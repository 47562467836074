<template>
  <div class="save-code">
    <div class="title-content">
      <img :src="require('@/assets/img/save/code.png')" alt="" class="code-img">
      <span class="title">房东网格</span>
    </div>
    <div class="house-content">
      <div class="house-name">{{ houseName }}</div>
      <div>
        <div class="btn" @click="toLogin">登录</div>
        <div class="btn btn1" @click="toRegister">自主登记</div>
      </div>
    </div>
    <div class="user-info">
      <div class="user-info-title">{{ managerNum }}员信息</div>
      <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
        <div class="user-info-item" v-for="item in userInfoList">
          <img :src="item.avatar" alt="">
          <div>
            <div class="post">{{ item.workName }}</div>
            <div class="name">{{ item.name }}</div>
            <div class="mobile">{{ getMobile(item.mobile) }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="notice-info">
      <div class="notice-title">公告<van-icon name="arrow" color="#D1D1D6" @click="goNotice()" /></div>
      <div class="notice-content" v-for="item in noticeList" @click="getInfo(item.id)">
        {{item.title}}
      </div>
    </div>
    <div class="notice-info">
      <div class="notice-title">楼幢逃生方案</div>
      <!-- <upload-file class="notice-img"
        :readonly="true"
        :file-list="safeImg"
        :delBtnShow="false"
        :uploadBtnShow="false"
      /> -->
      <div class="notice-img">
        <img :src="safeImg" @click="imgPreView(safeImg)" class="img" v-if="safeImg">
        <div id="default" class="img" v-if="!safeImg">
          <img :src="require('@/assets/img/safe-background.png')" class="in-img">
          <span style="color:#999999;">暂无内容~</span>
        </div>

      </div>
    </div>
    <div class="notice-info">
      <div class="notice-title">平安宣传<van-icon name="arrow" color="#D1D1D6" @click="goNoticeSafe()" /></div>
      <div class="notice-content" v-for="item in noticeListSafe" @click="getInfo(item.id)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getImageStream } from '@/utils/index'
import uploadFile from '@/components/upload/uploadFile'
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      openid:'',
      unionid:'',
      mobile:'',
      noticeListSafe: '',
      houseName: '',
      houseId: '',
      managerNum: '',
      orgId: '',
      safeImg:'',
      noticeType:'',
      subareaId:'',
      noticeList: [],
      userInfoList: [],
      buildingId:'',
      url:'https://sgg.hzxh.gov.cn/police-api',
      type: 1, //1是房屋id,2是楼幢id
      id: ''
    }
  },
  components: {
    uploadFile
  },
  created() {
    console.log(Vue.prototype)
    console.log(this.$route.query)
    if (this.$route.query.domain && this.$route.query.domain != 'null'&&!this.$route.query.p&&!this.$route.query.u){
      this.$http.changeUrl(this.$route.query.domain, true)
      this.$httpApp.changeUrl(this.$route.query.domain, true)
    }
    if (this.$route.query.params) {
      Vue.prototype.$appid = 'wxfa4ce77dc91e7327'
      sessionStorage.setItem('appid', 'wxfa4ce77dc91e7327')
      this.openid = this.$route.query.p
      this.unionid = this.$route.query.u
      this.mobile = this.$route.query.m
      this.params = this.$route.query.params
      this.domain = this.$route.query.domain
      this.$nextTick(() => {
        if (!this.openid && !this.unionid){
          // if(this.isWeixinBrowser()) {
          //   如果是微信内部浏览器就打开小程序
          const param = `params=${this.$route.query.params}` + `&domain=${this.$route.query.domain}` + `&code=safet2ycode`
          this.$http({
            url: this.$http.adornUrl('/wxapp/getWxUrlScheme'),
            method: 'post',
            params: this.$http.adornParams({
              param
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              location.href =data.wxUrlScheme

            }
          })
          // }
        }
      })
    }
      this.getScanCodeParams()

  },
  methods: {
    getInfo(id) {
      this.$store.commit('setNoticeId', id)
      this.$router.push('/notice-info')
    },
    goNoticeSafe() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=this.noticeType
      this.$router.push({path:'/notice',query:{orgId:tmp1,noticeType:tmp2}})
    },
    goNotice() {
      let tmp1=this.orgId|| Vue.prototype.$globalData.userInfo.orgId
      let tmp2=1
      this.$router.push({path:'/notice',query:{orgId:tmp1,isOpen:tmp2}})
    },
    getMobile(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        if (i == 3 || i == 7) {
          result.push(" " + value.charAt(i));
        } else {
          result.push(value.charAt(i));
        }
      }
      return result.join('')
    },
    numChange(i) {
      let arry = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
      if (i <= 10) {
        return arry[i];
      }
      if (i % 10 == 0 && i < 100) {
        return arry[Math.floor(i / 10)] + "十";
      }
      if (i > 10 && i < 20) {
        return "十" + this.numChange(i - 10 * Math.floor(i / 10));
      }
      if (i > 20 && i < 100 && i % 10 != 0) {
        return arry[Math.floor(i / 10)] + "十" + numChange(i - 10 * Math.floor(i / 10));
      }
    },
    getScanCodeParams() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$route.query.params|| Vue.prototype.$qrcodeOptions
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let houseNameList = data.data.houseName.split('-')
          this.houseId = data.data.houseId
          this.id = this.houseId
          this.buildingId = data.data.buildingId
          if (this.buildingId){
            this.type = 2
            this.id = this.buildingId
          }
          this.orgId = data.data.orgId
          this.houseName = houseNameList.splice(2, houseNameList.length - 1).join('-')
          if((this.unionid||Vue.prototype.$unionid)&&(this.openid||Vue.prototype.$openid)){
            this.isLogin()
          }
          this.getManager()
          this.getNoticeType()
          this.getNotice()
          this.getImg()
          if (this.orgId) {
            this.getStreetId(this.orgId)
          }
        }
      })
    },
    isLogin(){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/scanCode/isLogin`),
        method: 'get',
        params: this.$http.adornParams({
          unionId: this.unionid||Vue.prototype.$unionid,
          openId:this.openid||Vue.prototype.$openid,
          orgId:this.orgId,
          houseId: this.houseId||''
        })
      }).then(({data}) => {
        if (data.code == 0 && data) {
          if(this.buildingId&&data.isAdmin==true){
            sessionStorage.setItem('token',data.token)
            this.$cookies.set('token',data.token)
            this.$router.replace({path:'/AdminCode',query:{buildingId:this.buildingId||Vue.prototype.$buildingId,mobile:this.mobile|| Vue.prototype.$purePhoneNumber,
                data:this.$route.query.params|| Vue.prototype.$qrcodeOptions,orgId:this.orgId||
                  Vue.prototype.$globalData.userInfo.orgId
                ,userId:data.userId,assistId:data.assistId}})
            return;
          }
          else if(data.isAdmin==false&&data.isLive==false){
         return
          }
           if(this.houseId&&(data.isAdmin==true||data.isLive==true)) {
          sessionStorage.setItem('token',data.token)
          this.$cookies.set('token',data.token)
          this.$route.query.userId=data.userId
          this.$router.replace({
            path: '/userReal',
            query:this.$route.query
          })
           }

          // this.subareaId = data.buildingInfo.subarea
          // this.$route.query.type = 'zuke'
          // this.$route.query.subareaId = this.subareaId
          // this.$route.query.buildingId = this.buildingId
          // this.$router.push({ path: '/sso', query: this.$route.query})
        }
      })
    },
    getImg() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/info/getFireImg`),
        method: 'get',
        params: this.$http.adornParams({
          id: this.id,
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // this.safeImg=[{fileType:'image',relativePath:data.info.fireSafetyImg}]
          this.safeImg=data.info.fireSafetyImg?getImageStream(data.info.fireSafetyImg):''
          // this.safeImg=''
        }
      })
    },
    imgPreView (path) {
      ImagePreview({
        images: [path],
        startPosition: 0,
        closeable: true,
      });
    },
    getNotice() {
      this.$httpApp({
        url: this.$httpApp.adornUrl(`/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId,
          orgUserId: 0,
          isOpen: 1
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeList=data.page.list
        }
      })
    },
    getNoticeType() {
      this.$http({
        url: this.$http.adornUrl(`/sys/dict/listDictByCode`),
        method: 'get',
        params: this.$http.adornParams({
          code: 'noticeType',
          orgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          data.dicts.forEach(item => {
            if (item.label === '平安宣传' && item.parameters.type === 'safetyCode') {
              this.noticeType = item.value
            }
          })
          this.getNoticeSafe()
        }
      })
    },
    getNoticeSafe() {
      this.$httpApp({
        url:this.$httpApp.adornUrl( `/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.orgId|| Vue.prototype.$globalData.userInfo.orgId,
          orgUserId: 0,
          noticeType: this.noticeType
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeListSafe=data.page.list
        }
      })
    },
    getManager() {
      this.$httpApp({
        url: this.$httpApp.adornUrl( `/building/manager/info/getManager/${this.id}`),
        method: 'get',
        params: this.$httpApp.adornParams({
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let tmp = data.managers
          data.managers.map((item, index) => {
            tmp[index].avatar = getImageStream(item.avatar)
          })
          this.userInfoList = tmp
          this.managerNum = this.numChange(data.managers.length)
        }
      })
    },
    // 根据社区id获取街道id
    getStreetId(id) {
      this.$http({
        url: this.$http.adornUrl(`/sys/org/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          let street = data.sysOrg.path.split(',')
          let newStreet = street.splice(0, street.length - 1)
          this.streetPath = newStreet.slice(1).toString()
        }
      })
    },
    // 根据楼幢id获取小区楼幢
    getBuildingId(id) {
      this.$http({
        url: this.$http.adornUrl(`/building/info/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          this.subareaId = data.buildingInfo.subarea
          this.$route.query.type = 'zuke'
          this.$route.query.subareaId = this.subareaId
          this.$route.query.buildingId = this.buildingId
          this.$router.push({ path: '/newRegister', query: this.$route.query})
        }
      })
    },
    toLogin() {
      //+ `&path=${encodeURIComponent('pages/login/login')}`
      const param = `params=${this.$route.query.params||Vue.prototype.$qrcodeOptions}` + `&domain=${this.$route.query.domain||  Vue.prototype.$globalData.domain}`+ `&streetId=${this.streetPath||  Vue.prototype.$globalData.userInfo.street}`
      this.$http({
        url: this.$http.adornUrl('/wxapp/getWxUrlScheme'),
        method: 'post',
        params: this.$http.adornParams({
          param,
          path: '/pages/login/login'
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          location.href = data.wxUrlScheme
        }
      })
    },
    toRegister() {
      if (this.buildingId){
        this.getBuildingId(this.buildingId)
      }else if (this.houseId){
        console.log( this.$route.query)
        this.$route.query.type = 'zuke'
        this.$router.push({ path: '/newRegister', query: this.$route.query })
      } else {
        this.$message.error("页面加载未完成,请稍后再试!")
      }
    }
  }
}
</script>
<style scoped lang="scss">
.save-code {
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/img/save/saveCode-bg.png");
  padding-bottom: 24px;

  .title-content {
    margin: 54px auto 0;
    width: 690px;
    text-align: center;
    position: relative;
    height: 48px;
    line-height: 48px;

    .code-img {
      width: 52px;
      height: 52px;
      position: absolute;
      left: 0;
    }

    .title {
      font-size: 34px;
      color: #333;
    }
  }

  .house-content {
    width: 690px;
    height: 136px;
    margin: 44px auto 0;
    background-image: url("~@/assets/img/save/saveCode-cube.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;

    .house-name {
      font-size: 32px;
      color: #4581F8;
    }

    .btn {
      display: inline-block;
      margin-right: 24px;
      width: 136px;
      line-height: 52px;
      text-align: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid #4581F8;
      font-size: 24px;
      color: #4581F8;
    }

    .btn1 {
      background: linear-gradient(180deg, #9ABCFF 0%, #4581F8 100%);
      color: #fff;
    }
  }

  .content-title {
    height: 88px;
    color: #333;
    font-size: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-info {
    width: 750px;
    padding: 0 30px;
    background: #fff;
    margin-top: 24px;
    border-radius: 0px 0px 20px 20px;

    .user-info-title {
      height: 88px;
      color: #333;
      font-size: 36px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .user-info-item {
      width: 336px;
      height: 170px;
      background: #F8F9FA;
      display: flex;
      padding: 26px 18px;
      margin-bottom: 24px;

      img {
        width: 120px;
        height: 120px;
        margin-right: 12px;
      }

      .post {
        font-size: 26px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .name {
        font-size: 26px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .mobile {
        font-size: 24px;
        color: #666;
        line-height: 34px;
        margin-top: 4px;
      }
    }
  }

  .notice-info {
    width: 690px;
    margin: 24px 30px 0;
    background: #fff;
    border-radius: 20px;
    padding-bottom: 8px;

    .notice-title {
      height: 88px;
      color: #333;
      font-size: 36px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
    }

    .notice-content {
      padding: 0 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 16px;
      font-size: 28px;
      color: #666;
      line-height: 40px;
    }
    .notice-img
      #default{
        display:flex;
        flex-direction:column;
        justify-content: space-around;
        align-items: center;
      }
      .img{
      background: #F8F9FA;
      width: 642px;
      height: 370px;
      margin: 0 24px;
      margin-bottom: 16px;
      border-radius: 10px;
      border-width: 0;
      .in-img{
        max-width:"180px";
        max-height:"133px";
      }
    }
  }
}
</style>
